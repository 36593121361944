// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from './master/userMasterReduser'
import cityMasterData from './master/cityMasterReducer'
import passengerMasterData from './master/passengerMasterReducer'
import settingMasterData from './master/settingMasterReducer'

import profile from './profile'
import specialFeatureMasterData from './master/specialFeatureMasterDataReducer'
import landingDoorMasterData from './master/landingDoorMasterDataReducer'
import carDoorMasterData from './master/carDoorMasterDataReducer'
import typeOfLiftMasterData from './master/typeOfLiftReducer'
import copMasterData from './master/copMasterReducer'
import cabinMasterData from './master/cabinMasterReducer'
import masterMachineData from './master/machineMasterDataReducer '
import masterDesignationData from './master/designationMasterDataReducer'
import masterBreakdownCategoryData from './master/breakdownCategoryMasterReducer'
import breakdownReportData from './master/breakdownReportMasterReducer'
import breakdownIssueData from './master/breakdownIssueMasterReducer'
import speedMasterData from './master/speedMasterReducer'
import materialCategoryMasterData from './master/materialCategoryMasterDataReducer'
import materialMasterData from './master/materialMasterDataReducer'
import departmentMasterData from './master/departmentMasterDataReducer'
import firmMasterData from './master/firmMasterDataReducer'
import siteMasterData from './master/siteMasterDataReducer'
import contactPersonMasterData from './master/contactPersonMasterDataReducer'
import amcInspectionChecklistMasterData from './master/amcInspectionChecklistMasterDataReducer'
import payemenTermMasterData from './master/payemenTermMasterReducer'
import cabinFormData from './forms/cabinFormReducer'
import carCounterMasterData from './forms/carCounterMasterDataReducer'
import componentMasterData from './forms/componentMasterDataReducer'
import formInformationMasterData from './master/formInfoMasterReducer'
import machineFormData from './forms/machineFormReducer'
import InfoData from './master/infoReducer'
import ardFormData from './forms/ardFormDataReducer'
import leadReport from './report/leadReportReducer'
import amcReportData from './report/amcReportReducerData'
import jobReportData from './report/jobReportReducer'
import dashboardData from './dashboard/dashboardDataReducer'
// import wiringFormData from './master/wiringFormReducerData'
import amcInspectionIssueData from './master/amcInspectionIssueMasterReducer'
import mailTriggerReducer from './mailTrigger/mailTriggerReducer'
import settingData from './settingForWeb/settingReducer'
import lostReasonMasterData from "./master/lostReasonMasterReducer"
import followupRemarkMasterData from "./master/followupRemarkMasterReducer"
import leadSourceMasterData from "./master/leadSourceMasterReducer"
import userTrackingData from './tracking/userTracking'
import locationTracking from './tracking/locationTracking'
import superAdminConfigreducer from './superAdminConfig/superAdminConfigReducer'
import productCategoryMasterData from './productInventory/productCategoryMasterDataReducer'
import productMasterData from './productInventory/productMasterDataReducer'
import unitMasterData from './productInventory/unitMasterDataReducer'
import supplierMasterData from './productInventory/supplierMasterDataReducer'
import expenseMasterData from './productInventory/expenseMasterDataReducer'
import purchaseMasterData from './productInventory/purchaseMasterDataReducer'
import purchaseHistoryData from './productInventory/purchaseHistoryReducerData'
import deliveryChallanData from './productInventory/deliveryChallanDataReducer'
import productInventoryReportData from './productInventory/ProductInventoryReportReducerData'
import tnCMasterReducer from './tncMaster/tnCMasterReducer'
import listingDCReducer from './productInventory/listingDCReducer'
import dcSummaryReportReducer from './productInventory/dcSummaryReducer'
import manageExpensesReducer from './productInventory/manageExpenseReducer'
import weekOfRosterReducer from './attendance/weekOffRosterReducer'
import holidayReducer from './attendance/HolidayMasterReducer'
import weeklyOffReducer from './attendance/WeeklyOffMasterReducer'
import shiftReducer from './attendance/ShiftMasterReducer'
import summeryReportReducer from './attendance/SummeryReportReducer'
import AttendanceReportReducer from './attendance/AttendanceReportReducer'
import monthlyAttendanceReportReducer from './attendance/MonthlyAttendanceReducer'
import hrSettingReducer from './attendance/HrSettingMasterReducer'
import approvalMasterReducer from './attendance/LeaveApprovalMasterReducer'
import whatsappTemplateMasterReducer from './master/whatsappTemplateMasterReducer'
import leadReportReducer from './report/leadNewReportReducer'
import qrDataReducer from './master/breakdownQrReducer'
import jobHistoryReportReducer from './report/jobHistoryReportReducer'
import warehouseMasterReducer from './productInventory/wareHouseMasterReducer'
import breakDownReportReducer from './report/breakDownReportReducer'
import rapairReportReducer from './report/repairOrderReportReducer'
import ropeMasterReducer from './master/ropeMasterReducer'
import ceilingMasterReducer from './master/ceilingMasterReducer'
import flooringMasterReducer from './master/flooringMasterReducer'
import followUpReducer from './report/followUpReportReducer'
import stageMasterReducer from './master/stageMasterReducer'
import taskMasterReducer from './master/taskMasterReducer'
import pmCheckPointReducer from './master/pmCheckPointReducer'
import shaftMasterReducer from './master/shaftMasterReducer'
import branchMasterReducer from './master/branchMasterReducer'
import extraElementReducer from './master/extraElementReducer'
import controllerMasterReducer from './master/controllerMasterReducer'
import systemMasterReducer from './master/systemMasterReducer'
import amc2ReportData from './report/amc2ReportReducer'
import leadsData from './leads/leadsReducer'
import gstMasterReducer from './master/gstMasterDataReducer'
import phaseMasterReducer from './master/phaseMasterReducer'
import areaMasterData from './master/areaMasterReducer '
import galleryViewMasterData from './master/galleryViewMasterReducer'
import motorMasterData from './master/motorMasterDataReducer'
import quotationReportStore from './report/quotationReportReducer'
import pmsReportReducer from './report/pmsReportReducer'
import metaFieldsMasterReducer from './master/metaFieldMasterDataReducer'
import nameSettingsMasterData from './master/nameSettingsMasterDataReducer'
import monthlyUserPerformanceReportReducer from './report/monthlyUserPerformanceReportReducer'
import requiredFieldMasterReducer from './master/requiredFeildMasterReducer'
import bomReducer from './productInventory/bomReducer'
import breakDownPriorityStatusData from './master/breakdownPriorityReducer'
import whatsNewReducerData from './master/whatsNewReducer'


const rootReducer = combineReducers({
  auth,
  profile,
  navbar,
  layout,
  users,
  cityMasterData,
  passengerMasterData,
  settingMasterData,
  specialFeatureMasterData,
  landingDoorMasterData,
  carDoorMasterData,
  typeOfLiftMasterData,
  copMasterData,
  cabinMasterData,
  masterMachineData,
  masterDesignationData,
  masterBreakdownCategoryData,
  breakdownReportData,
  breakdownIssueData,
  speedMasterData,
  materialCategoryMasterData,
  materialMasterData,
  departmentMasterData,
  firmMasterData,
  siteMasterData,
  contactPersonMasterData,
  amcInspectionChecklistMasterData,
  payemenTermMasterData,
  cabinFormData,
  carCounterMasterData,
  componentMasterData,
  formInformationMasterData,
  machineFormData,
  ardFormData,
  InfoData,
  leadReport,
  amcReportData,
  jobReportData,
  dashboardData,
  amcInspectionIssueData,
  mailTriggerReducer,
  settingData,
  lostReasonMasterData,
  followupRemarkMasterData,
  leadSourceMasterData,
  userTrackingData,
  locationTracking,
  userTrackingData,
  locationTracking,
  lostReasonMasterData,
  followupRemarkMasterData,
  leadSourceMasterData,
  superAdminConfigreducer,
  productCategoryMasterData,
  productMasterData,
  unitMasterData,
  supplierMasterData,
  expenseMasterData,
  purchaseMasterData,
  purchaseHistoryData,
  deliveryChallanData,
  productInventoryReportData,
  tnCMasterReducer,
  listingDCReducer,
  dcSummaryReportReducer,
  manageExpensesReducer,
  weekOfRosterReducer,
  holidayReducer,
  weeklyOffReducer,
  shiftReducer,
  summeryReportReducer,
  AttendanceReportReducer,
  monthlyAttendanceReportReducer,
  hrSettingReducer,
  approvalMasterReducer,
  whatsappTemplateMasterReducer,
  leadReportReducer,
  qrDataReducer,
  jobHistoryReportReducer,
  warehouseMasterReducer,
  breakDownReportReducer,
  rapairReportReducer,
  ropeMasterReducer,
  ceilingMasterReducer,
  flooringMasterReducer,
  followUpReducer,
  stageMasterReducer,
  taskMasterReducer,
  pmCheckPointReducer,
  shaftMasterReducer,
  branchMasterReducer,
  extraElementReducer,
  controllerMasterReducer,
  systemMasterReducer,
  amc2ReportData,
  leadsData,
  gstMasterReducer,
  phaseMasterReducer,
  areaMasterData,
  galleryViewMasterData,
  motorMasterData,
  quotationReportStore,
  pmsReportReducer,
  metaFieldsMasterReducer,
  nameSettingsMasterData,
  monthlyUserPerformanceReportReducer,
  requiredFieldMasterReducer,
  bomReducer,
  breakDownPriorityStatusData,
  whatsNewReducerData

})

export default rootReducer
