import React from 'react';
import { Helmet } from 'react-helmet';
import  secureLocalStorage  from  "react-secure-storage";

export const HelmetInfo = () => {
    const settingData = JSON.parse(secureLocalStorage.getItem('settingData'));
    return (
        <div>
            <Helmet>
                {/* <link rel="icon" href={faviconImage} type="image/x-icon" /> */}
                <link rel="shortcut icon" href={settingData?.small_logo} type="image/x-icon" />
            </Helmet>
            {/* Your component content goes here */}
        </div>
    );
}
